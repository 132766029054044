<!-- video bg -->
<video width="100%" height="100%" [muted]="true" [src]="slides ? slides[activeIndexInCarousel].fields.videoBackground.fields.file.url : null" playsinline="true" loop="true" muted="true" autoplay="true">
</video>

<!-- dots at the bottom -->
<div class="carouselDots" *ngIf="slides && slides.length > 1">
    <button aria-hidden="true" tabindex="-1" (click)="changeSlideByIndex(i)" [class.active]="i == activeIndexInCarousel" *ngFor="let slide of slides; let i = index;">
        <fa-icon [icon]="['fas', 'circle']" ></fa-icon>
    </button>
</div>

<!-- arrows -->
<div class="seekArrowsContainer" *ngIf="slides && slides.length > 1">
    <button (click)="seekSlide('previous')">
        <fa-icon [icon]="['fas', 'angle-left']"></fa-icon>
    </button>

    <button (click)="seekSlide('next')">
        <fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
    </button>
</div>

<!-- content -->
<div class="slideContentContainer">
    <h2>
        {{slides ? slides[activeIndexInCarousel].fields.heading : ""}}
    </h2>
    <p>
        {{slides ? slides[activeIndexInCarousel].fields.subheading : ""}}
    </p>
</div>
