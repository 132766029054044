<h2>Search Results</h2>

<img src="assets/images/loader.gif" alt="loading..." *ngIf="searchLoading" id="loader">

<!-- empty statex -->
<p *ngIf="!searchLoading && blogPostSearchResults.length == 0 && linkSearchResults.length == 0" class="emptyState">
    There were no results matching your search.
</p>

<h3 *ngIf="blogPostSearchResults.length > 0">Pages</h3>
<ul>
    <li *ngFor="let result of blogPostSearchResults" [routerLink]="'/pages/prmcBlogPost/' + result.fields.slug">
        <div class="resultContentContainer">
            <div>{{result.fields.heading ? result.fields.heading : result.fields.slug}}</div>
            <p>{{result.fields.subheading ? result.fields.subheading : 'Click to read more...'}}</p>
        </div>
        <div class="resultChevron">
            <fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
        </div>
    </li>
</ul>

<ul>
    <li *ngFor="let result of postSearchResults" [routerLink]="'/pages/prmcPost/' + result.fields.slug">
        <div class="resultContentContainer">
            <div>{{result.fields.heading ? result.fields.heading : result.fields.slug}}</div>
            <p>{{result.fields.subheading ? result.fields.subheading : 'Click to read more...'}}</p>
        </div>
        <div class="resultChevron">
            <fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
        </div>
    </li>
</ul>

<ul>
    <li *ngFor="let result of articleSearchResults" [routerLink]="'/pages/prmcArticle/' + result.fields.slug">
        <div class="resultContentContainer">
            <div>{{result.fields.heading}}</div>
            <p>{{result.fields.subheading ? result.fields.subheading : 'Click to read more...'}}</p>
        </div>
        <div class="resultChevron">
            <fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
        </div>
    </li>
</ul>

<h3 *ngIf="linkSearchResults.length > 0">Links</h3>
<ul>
    <li *ngFor="let result of linkSearchResults" [appHandleCMSLink]="result">
        <div class="resultContentContainer">
            <div>{{result.fields.displayText}}</div>
            <p>External link</p>
        </div>
        <div class="resultChevron">
            <fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
        </div>
    </li>
</ul>