import { Component, Input, OnInit } from '@angular/core';
import { IPrmcSiteWideAlert } from 'src/app/interfaces/contentful';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Input() alert?: IPrmcSiteWideAlert;

  constructor() { }

  ngOnInit(): void {
  }

}
