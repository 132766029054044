<section>
  <div class="container">
    <div class="boxStyle">
      <h1>
        The page you're looking for hasn't been found.
      </h1>
      <div class="containerContent">
        <p>Go back or use search/navigation to keep browsing.</p>
      </div>
    </div>
  </div>
</section>
