import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, take, takeUntil } from 'rxjs';
import { WINDOW } from 'src/app/app.module';
import { IPrmcBlogPost } from 'src/app/interfaces/contentful';
import { ContentfulService } from 'src/app/services/contentful.service';
import { MetaTagService } from 'src/app/services/meta-tag.service';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BlogPostComponent implements OnInit {
  blogContent?: IPrmcBlogPost;
  private componentDestroyed = new Subject<void>();
  isMutipleImage: boolean = false;
  isArticlesOrPost: boolean = false;
  IsNZSite: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Inject(WINDOW) private window: Window
  ) {
    this.watchForRouteChange();
  }

  /**
   * Subcribes to the data from the resolver. If there is no content, it will send the user to the not found page.
   */
  watchForRouteChange() {
    this.route.data.pipe(takeUntil(this.componentDestroyed)).subscribe(res => {
      this.blogContent = res['content'] as IPrmcBlogPost;

      if (!this.blogContent) {
        //no data was found
        console.error("Blog not found");
        this.router.navigateByUrl('/page-not-found');
      } else {
        this.onContentLoad();
      }
    })
  }

  /**
   * Get content for the currently requested blog post
   */
  onContentLoad() {
    //first get the page slug and page type
    const pageType = this.route.snapshot.paramMap.get("page-type");
    //Primary Connect NZ Fuel Levy issue Fix DVAO-192
    //Loading NZ spefic nzSpecificBodyContent by checking the url extension  of hosted site and the flag enableNzSpecificBodyContent
    // If Page is loaded from 'www.primaryconnect.co.nz'  or If the URL end with 'nz' flag 'IsNZSite' is set true
    //other wise flag IsNZSite is set flase
    if (window.location.host.slice(-2) === 'nz') {
      this.IsNZSite = true;
    } else {
      this.IsNZSite = false;
    }
    // prmcArticle and prmcPost no need of heding in Image
    if (pageType === "prmcArticle" || pageType === "prmcPost") {
      this.isArticlesOrPost = true;
    } else {
      this.isArticlesOrPost = false;
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

}
