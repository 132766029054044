import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TitleService } from '../services/title.service';

@Injectable({
  providedIn: 'root'
})
export class NzHomePageRedirectGuard implements CanActivate {
  constructor(private router: Router, private titleService: TitleService) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree  {
      const isNZSite = this.titleService.isNewZealandSite();
      if (isNZSite) {
        return this.router.parseUrl('/new-zealand');
      } else {
        return true;
      }
    }
  
}
