import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MetaTagService {

  constructor(
    private router: Router,
    private meta: Meta
  ) {
    this.removeMetaTagOnRouteChange();
  }

  /**
 * Updates the description meta tag (or creates one if there isn't an existing one).
 * @param content The tag description with which to replace the existing tag content.
 */
  updateMetaTag(content: string): void {
    this.meta.updateTag({ name: 'description', content: content });
  }

  /**
   * Removes a meta Description tag element from the current HTML document on route change.
   * we're doing this because meta tags are usually page-specific, so when we switch pages we want to remove all meta tags.
   */
  removeMetaTagOnRouteChange(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.meta.removeTag('name="description"');
      }
    });
  }

}
