<app-carousel *ngIf="homePageContent?.fields?.carousel?.fields?.slides"
  [slides]="homePageContent?.fields?.carousel?.fields?.slides"></app-carousel>

<img src="assets/images/loader.gif" alt="loading..." *ngIf="isLoading" id="loader">

<!-- contact info -->
<div id="contactInfo" *ngIf="(homePageContent?.fields?.contactDetails || homePageContent?.fields?.contactDetailsNz)">
  <div class="contactInfoCard"
    *ngFor="let contactDetail of (isNZSite ? homePageContent?.fields?.contactDetailsNz : homePageContent?.fields?.contactDetails)">
    <a [href]="contactDetail.fields.url">
      <fa-icon *ngIf="contactDetail.fields.icon" [icon]="[
          contactDetail.fields.icon.fields.iconFamily,
          contactDetail.fields.icon.fields.iconName  
        ]"></fa-icon>
      <span>
        {{ contactDetail.fields.displayText }}
      </span>
    </a>
  </div>
</div>

<!-- tagline -->
<div id="tagline" *ngIf="homePageContent?.fields?.tagline">
  {{ homePageContent?.fields?.tagline }}
</div>

<!-- links -->
<div id="CTALinks" *ngIf="homePageContent?.fields?.secondaryNavLinks">
  <ul>
    <li *ngFor="let navLink of homePageContent?.fields?.secondaryNavLinks" [appHandleCMSLink]="navLink">
      <button>
        <fa-icon *ngIf="navLink.fields.icon" [icon]="[
            navLink.fields.icon.fields.iconFamily,
            navLink.fields.icon.fields.iconName
          ]"></fa-icon>
        <span>
          {{ navLink.fields.displayText }}
        </span>
      </button>
    </li>
  </ul>
</div>

<ng-container *ngIf="
    homePageContent?.fields?.showNewsFeed &&
    newsFeedContent &&
    newsFeedContent.length == 3
  ">
  <h2 class="h2">{{ homePageContent?.fields?.newsFeedHeading }}</h2>

  <section>
    <!-- featured news tile -->
    <div class="newsRow">
      <div class="newsTile">
        <div class="newsTileContent">
          <h3>{{ newsFeedContent[0].fields.heading }}</h3>
          <p>{{ newsFeedContent[0].fields.subheading }}</p>
          <div>
            <a [routerLink]="'/pages/prmcPost/' + newsFeedContent[0].fields.slug">
              Read More
            </a>
          </div>
        </div>

        <div class="newsTileImage" [style.backgroundImage]="
            'url(' +
            newsFeedContent[0].fields.cardImage?.fields?.file?.url +
            ')'
          "></div>
      </div>
    </div>

    <!-- regular news tile -->
    <div class="newsRow">
      <div class="newsTile compactNewsTile">
        <div class="newsTileContent">
          <h3>{{ newsFeedContent[1].fields.heading }}</h3>
          <p>{{ newsFeedContent[1].fields.subheading }}</p>
          <div>
            <a [routerLink]="'/pages/prmcPost/' + newsFeedContent[1].fields.slug">
              Read More
            </a>
          </div>
        </div>

        <div class="newsTileImage" [style.backgroundImage]="
            'url(' +
            newsFeedContent[1].fields.cardImage?.fields?.file?.url +
            ')'
          "></div>
      </div>

      <div class="newsTile compactNewsTile">
        <div class="newsTileContent">
          <h3>{{ newsFeedContent[2].fields.heading }}</h3>
          <p>{{ newsFeedContent[2].fields.subheading }}</p>
          <div>
            <div>
              <a [routerLink]="
                  '/pages/prmcPost/' + newsFeedContent[2].fields.slug
                ">Read More</a>
            </div>
          </div>
        </div>

        <div class="newsTileImage" [style.backgroundImage]="
            'url(' +
            newsFeedContent[2].fields.cardImage?.fields?.file?.url +
            ')'
          "></div>
      </div>
    </div>

    <a class="sectionCTA" [routerLink]="'/category/prmcPost'">
      {{ homePageContent?.fields?.newsFeedCta?.fields?.displayText }}
    </a>
  </section>
</ng-container>

<ng-container *ngIf="
    homePageContent?.fields?.showPartnerFeed &&
    partnerFeedContent &&
    partnerFeedContent.length > 0
  ">
  <h2 class="h2">{{ homePageContent?.fields?.partnerFeedHeading }}</h2>
  <section>
    <div class="newsRow">
      <ng-container *ngFor="let partnerFeed of partnerFeedContent">
        <div class="newsTile compactNewsTile">
          <div class="newsTileContent">
            <h3>{{ partnerFeed.fields.heading }}</h3>
            <p>{{ partnerFeed.fields.subheading }}</p>
            <div>
              <a [routerLink]="'/pages/prmcArticle/' + partnerFeed.fields.slug">
                Read More
              </a>
            </div>
          </div>

          <div class="newsTileImage" [style.backgroundImage]="
              'url(' + partnerFeed.fields.cardImage?.fields?.file?.url + ')'
            "></div>
        </div>
      </ng-container>
    </div>

    <a class="sectionCTA" [routerLink]="'/category/prmcArticle'">
      {{ homePageContent?.fields?.partnerFeedCta?.fields?.displayText }}
    </a>
  </section>
</ng-container>