import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MetaTagService } from 'src/app/services/meta-tag.service';
import { TitleService } from 'src/app/services/title.service';
export class Hero {

  constructor(
    public id: number,
    public name: string,
    public power: string,
    public alterEgo?: string
  ) { }

}
@Component({
  selector: 'app-enquiries',
  templateUrl: './enquiries.component.html',
  styleUrls: ['./enquiries.component.scss']
})

export class EnquiriesComponent implements OnInit {

  url: string = "https://primaryconnect.secure.force.com/transport/PC_WebToLead";
  urlSafe!: SafeResourceUrl;
  isLoading = true;
  constructor(
    public sanitizer: DomSanitizer,
    private metaTagService: MetaTagService,
    private titleService: TitleService
    ) { }

  ngOnInit() {
    this.isLoading = true;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.metaTagService.updateMetaTag("Send us your queries and we will get back to you as soon as possible.");
    this.titleService.setTitle("Enquiries");
  }
  onLoaded() {
    this.isLoading = false;
  }
}
