 
<section class="blogPost">
  <div class="heroImage" *ngIf="blogContent?.fields?.heroImage?.fields?.file?.url" [style.background-image]="
      'url(' + blogContent?.fields?.heroImage?.fields?.file?.url + ')'
    ">
    <div class="heroTitle" *ngIf="
        (blogContent?.fields?.heading || blogContent?.fields?.subheading) &&
        !isArticlesOrPost
      ">
      <h2>{{ blogContent?.fields?.heading }}</h2>
      <p>{{ blogContent?.fields?.subheading }}</p>
    </div>
  </div>


  <section class="blogBodyWrapper blogContent">

    <div class="articlesOrPost" *ngIf="isArticlesOrPost">
      <h2>{{ blogContent?.fields?.heading }}</h2>
    </div>

    <!-- Primary Connect NZ Fuel Levy issue Fix DVAO-192
    Loading NZ spefic nzSpecificBodyContent by checking the url extension  of hosted site and the flag enableNzSpecificBodyContent
    If Page is loaded from 'www.primaryconnect.co.nz'(  If the URL end with 'nz' )  and the flag enableNzSpecificBodyContent = true the nzSpecificBodyContent body will be displayed
    If Page is loaded from 'www.primaryconnect.co.nz'( If the URL end with 'nz' )  and the flag enableNzSpecificBodyContent = flase the bodyContent body will be displayed
    If page is loaded from 'https://www.primaryconnect.com.au/' flag enableNzSpecificBodyContent = true the bodyContent body will be displayed
    If page is loaded from 'https://www.primaryconnect.com.au/' and the flag enableNzSpecificBodyContent = flase the bodyContent body will be displayed -->
    <ng-container *ngIf="
        blogContent?.fields?.enableNzSpecificBodyContent &&
          blogContent?.fields?.nzSpecificBodyContent &&
          IsNZSite;
        else showBodyContent
      ">
      <div [appRenderRichText]="blogContent?.fields?.nzSpecificBodyContent"></div>
    </ng-container>

    <div class="cardContainer">
      <ng-container *ngFor="let content of blogContent?.fields?.additionalBodyContent">
        <container-element [ngSwitch]="content.sys.contentType.sys.id">
          <!-- rich text -->
          <div [appRenderRichText]="content.fields['content']" *ngSwitchCase="'prmcRichText'">
            ...
          </div>
          <!-- prmc Image Text Content -->
          <div class="cardContent" *ngSwitchCase="'prmcImageTextContent'">
            <div [appRenderRichText]="content.fields['imageTextContent']">
              ...
            </div>
          </div>
          <!-- other components can be displayed with switch cases below -->
          <!--default case when there are no matches, let them know we don't know how to render this content i.e. it's unsupported -->
          <div *ngSwitchDefault>
            Unsupported content type: {{ content.sys.contentType.sys.id }}
          </div>
        </container-element>
      </ng-container>
    </div>
  </section>
  <div *ngIf="blogContent?.fields?.pageEndImage?.fields?.file?.url" class="pageEndImage" [style.backgroundImage]="
      'url(' + blogContent?.fields?.pageEndImage?.fields?.file?.url + ')'
    "></div>
</section>
 
<ng-template #showBodyContent>
  <!-- contact info -->
  <div id="contactInfo" *ngIf="(blogContent?.fields?.contactDetails)">
    <div class="contactInfoCard" *ngFor="let contactDetail of  blogContent?.fields?.contactDetails">
      <a [href]="contactDetail.fields.url">
        <fa-icon *ngIf="contactDetail.fields.icon && contactDetail.fields.icon.fields" 
        [icon]="[
          contactDetail.fields.icon.fields.iconFamily,
          contactDetail.fields.icon.fields.iconName  
        ]"></fa-icon>
        <span>
          {{ contactDetail.fields.displayText }}
        </span>
      </a>
    </div>
  </div>
  <div [appRenderRichText]="blogContent?.fields?.bodyContent"></div>
</ng-template>