import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, firstValueFrom, takeUntil } from 'rxjs';
import { ContentfulService } from 'src/app/services/contentful.service';
import { IPrmcArticle, IPrmcBlogPost, IPrmcLink, IPrmcPost } from '../../interfaces/contentful';
import { MetaTagService } from 'src/app/services/meta-tag.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {

  private componentDestroyed = new Subject<void>();
  public blogPostSearchResults: IPrmcBlogPost[] = [];
  public articleSearchResults: IPrmcArticle[] = [];
  public postSearchResults: IPrmcPost[] = [];
  public linkSearchResults: IPrmcLink[] = [];
  public searchLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private cms: ContentfulService,
    private metaTagService: MetaTagService,
    private titleService: TitleService
  ) {
    this.watchForRouteChange();
  }

  ngOnInit(): void {
    this.metaTagService.updateMetaTag("About Primary Connect - Primary connect operates a National distribution network, consisting of road, rail and ocean transport services and 3PL (Third Party Logistics) storage facilities.");
    this.titleService.setTitle("Search Results")
  }

  /**
 * If the user searches for something else, the route will change but since the target component is the same, the page won't refresh.
 * So we need to watch for changes to the route
 */
  watchForRouteChange() {
    this.route.paramMap
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(params => {

        //reset the search results before searching again
        this.blogPostSearchResults = [];
        this.articleSearchResults = [];
        this.postSearchResults = [];
        this.linkSearchResults = [];

        this.getSearchResults();
      });
  }


  async getSearchResults() {
    //get the query string from the route
    const encodedSearchString = this.route.snapshot.paramMap.get("search-string");

    this.searchLoading = true;

    if (!encodedSearchString) {
      this.searchLoading = false;
      return;
    }

    const searchString = decodeURIComponent(encodedSearchString);

    [this.blogPostSearchResults, this.postSearchResults, this.articleSearchResults, this.linkSearchResults] = await Promise.all([
      firstValueFrom(this.cms.getContent("prmcBlogPost", { query: searchString })) as Promise<IPrmcBlogPost[]>,
      firstValueFrom(this.cms.getContent("prmcPost", { query: searchString })) as Promise<IPrmcPost[]>,
      firstValueFrom(this.cms.getContent("prmcArticle", { query: searchString })) as Promise<IPrmcArticle[]>,
      firstValueFrom(this.cms.getContent("prmcLink", { query: searchString, 'fields.isExternal': true })) as Promise<IPrmcLink[]>
    ]);

    this.searchLoading = false;
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }


}
