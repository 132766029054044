import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, firstValueFrom } from 'rxjs';
import { ContentfulService } from '../services/contentful.service';
import { IPrmcLegacyUrlMappingToNewUrl } from '../interfaces/contentful';

//The guard will redirect legacy URl to the updated page .
//The URLMapping  is fetched from the contentful.Checks the current route to see if we have a redirect for it.
// If we do, navigate to the redirect route, otherwise go to the not found page if no match the page is redirect to page not found

@Injectable({
  providedIn: 'root'
})
export class NotFoundDynamicRedirectGuardGuard implements CanActivate {
  constructor(public router: Router,
    private cms: ContentfulService
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //redirecting  Legacy URLs to New URLs
    return this.getRedirectUrl(state.url);

  }

  /**
  * Checks the current route to see if we have a redirect for it. If we do, navigate to the redirect route, otherwise go to the not found page.
  * @param requestUrl - the Legacy URL that need to redirect to new  url
  */
  async getRedirectUrl(requestUrl: string): Promise<any> {
    try {
      const mappedRedirectURL = await this.getRedirectUrlContent();
      const redirectURL = mappedRedirectURL.get(requestUrl.trim()) as string;
      //Retuning new URL if No match found redirecting to page not found
      return this.router.navigate([redirectURL ? redirectURL : '/page-not-found']);
    } catch (error) {
      console.error(error);
      //If any error on data fetch and process redirecting to page not found
      return this.router.navigate(['/page-not-found']);
    }
  }

  /**
  *Fetches the URLMapping for redirects
  *Attempts to get the data from sessionStorage, otherwise makes a call to the CMS.
  */
  async getRedirectUrlContent(): Promise<any> {

    if (sessionStorage.getItem('legacyUrlMapping') == null) {
      const postSearchResults = await firstValueFrom(this.cms.getContent("prmcLegacyUrlMappingToNewUrl")) as IPrmcLegacyUrlMappingToNewUrl[];
      const redirectURLMap: any = postSearchResults[0]?.fields.redirectUrlMap;
      sessionStorage.setItem('legacyUrlMapping', JSON.stringify(redirectURLMap));
      return (new Map(redirectURLMap));

    } else {
      const redirectURLMap: any = JSON.parse(sessionStorage.getItem('legacyUrlMapping') as string);
      return (new Map(redirectURLMap));
    }
  }


}

