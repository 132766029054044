<app-nav></app-nav>


<!-- we need a spacer to account for the nav being at the top of the page and being position:fixed -->
<div id="navSpacer"></div>

<!-- alert / notice -->
<app-alert *ngIf="isAlertVisible" [alert]="alert"></app-alert>


<router-outlet></router-outlet>
<app-navigation-pills></app-navigation-pills>
<app-footer></app-footer>
<app-author-controls></app-author-controls>