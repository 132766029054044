<nav class="navBar">
    <button id="toggleMenuBtn" (click)="isSlideOutNavOpen = !isSlideOutNavOpen">
        <fa-icon aria-label="toggle menu visibility" [icon]="['fas', 'bars']" aria-hidden="true"></fa-icon>
    </button>

    <a [routerLink]="['/']" aria-label="Go to home page">
        <img src="assets/images/logo.jpg" alt="Primary Connect logo" class="logo">
        <h1 id="hiddenSEOHeading">Primary Connect</h1>
    </a>

    <ul class="navItemsContainer">
        <li class="navItem" *ngFor="let item of navItems?.fields?.navigationItems">
            <div [appHandleCMSLink]="item.fields.link">
                {{item.fields.link?.fields?.displayText}}
            </div>

            <!-- the dropdown section of the nav item -->
            <div class="navDropdown" *ngIf="item?.fields?.dropDownItems">
                <div class="copyWrapper">
                    <div class="title">{{item.fields.dropDownTitle}}</div>
                    <div class="description">
                        {{item.fields.dropDownDescription}}
                    </div>
                </div>
                <ul class="dropdownLinks">
                    <li *ngFor="let dropdownItem of item.fields.dropDownItems">
                        <a [appHandleCMSLink]="dropdownItem">
                            <fa-icon [icon]="['fas', 'chevron-right']" aria-hidden="true"></fa-icon>
                            <span>
                                {{dropdownItem.fields.displayText}}
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
    </ul>


    <form (submit)="search()" class="searchWrapper">
        <label for="search">Search</label>
        <input type="text" [(ngModel)]="searchString" name="search" placeholder="Search...">
        <button type="submit" aria-label="search" class="searchBtn">
            <fa-icon [icon]="['fas', 'search']" aria-hidden="true"></fa-icon>
        </button>
    </form>
</nav>

<!-- slide out navigation -->
<div class="navSlideOutDimmer" [class.open]="isSlideOutNavOpen" (click)="isSlideOutNavOpen = false">

</div>

<nav class="navSlideOut" [class.open]="isSlideOutNavOpen">
    <div class="slideOutNavItemsContainer">
        <button (click)="isSlideOutNavOpen = false" aria-label="closeNav" class="closeBtn">
            <fa-icon [icon]="['fas', 'times']" aria-hidden="true"></fa-icon>
            <span>Close</span>
        </button>
        <ul>
            <li class="slideOutNavItem" *ngFor="let item of navItems?.fields?.navigationItems">
                <a [appHandleCMSLink]="item.fields.link" (click)="isSlideOutNavOpen = false"
                    class="categoryName">{{item.fields.dropDownTitle}}</a>

                <ul *ngIf="item.fields?.dropDownItems?.length">
                    <li class="subLink" *ngFor="let subItem of item.fields.dropDownItems" [appHandleCMSLink]="subItem"
                        role="link" (click)="isSlideOutNavOpen = false">
                        {{subItem.fields.displayText}}
                    </li>
                </ul>

            </li>
        </ul>
    </div>
</nav>