import { Component, HostBinding, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { WINDOW } from '../app.module';
import { ContentfulService } from '../services/contentful.service';

@Component({
  selector: 'app-author-controls',
  templateUrl: './author-controls.component.html',
  styleUrls: ['./author-controls.component.scss']
})
export class AuthorControlsComponent {
  @HostBinding('class.isExpanded') isExpanded: boolean = true;

  @HostBinding('class.available') isAuthorControlsAvailable: boolean = false;

  constructor(public cms: ContentfulService, @Inject(WINDOW) private window: Window) {
    this.isAuthorControlsAvailable = this.getIsAuthorControlsAvailable(environment.urlToShowPreviewOptions);
    this.cms.isPreviewModeEnabled = this.isPreviewEnabledFromLocalStorage();
  }

  getIsAuthorControlsAvailable(urlToShowPreviewOptions: string): boolean {
    if (this.window.location.host === urlToShowPreviewOptions) {
      return true;
    }

    return false;
  }

  isPreviewEnabledFromLocalStorage(): boolean {
    const isPreviewEnabled = localStorage.getItem('isPreviewEnabled');

    if (isPreviewEnabled === 'true') {
      return true;
    }
    return false;
  }

  public enablePreview() {
    localStorage.setItem('isPreviewEnabled', 'true');
    this.window.location.reload();
  }

  public disablePreview() {
    localStorage.setItem('isPreviewEnabled', 'false');
    this.window.location.reload();
  }
}
