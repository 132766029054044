import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { ContentfulService } from './services/contentful.service';
import { take } from 'rxjs';
import { IPrmcSiteWideAlert } from './interfaces/contentful';

declare var gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'primary-connect-headless';
  isAlertVisible = false;
  alert?: IPrmcSiteWideAlert;

  constructor(
    private router: Router,
    private titleService: Title,
    private cms: ContentfulService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-JBCP624454', {
          page_path: event.urlAfterRedirects,
          page_title: this.titleService.getTitle(),
        });
      }

      this.getAlert();

    });
  }

  /**
   * Gets the site-wide alert from contentful
   */
  getAlert() {
    this.cms.getContent('prmcSiteWideAlert', { include: 1, limit: 1, order: '-sys.createdAt' }).pipe(take(1)).subscribe(res => {

      if (!res[0]) {
        this.isAlertVisible = false;
        return;
      }

      let alert = res[0] as IPrmcSiteWideAlert;
      if (alert.fields.visible) {
        this.isAlertVisible = true;
        this.alert = alert;
      }
    });
  }
}
