import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlogPostComponent } from './pages/blog-post/blog-post.component';
import { CategoryComponent } from './pages/category/category.component';
import { HomeComponent } from './pages/home/home.component';
import { SearchResultsComponent } from './pages/search-results/search-results.component';
import { EnquiriesComponent } from './pages/enquiries/enquiries.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { NotFoundDynamicRedirectGuardGuard } from './guard/not-found-dynamic-redirect-guard.guard';
import { BlogPostResolver } from './pages/blog-post/blog-post.resolver';
import { NZHomeComponent } from './pages/nz-home/nz-home.component';
import { NzHomePageRedirectGuard } from './guard/nz-home-page-redirect.guard';
const routes: Routes = [
  { path: '', component: HomeComponent,  canActivate: [NzHomePageRedirectGuard] },
  { path: 'new-zealand', component: NZHomeComponent },
  { path: 'pages/:page-type/:page-slug', component: BlogPostComponent, resolve: { content: BlogPostResolver } },
  { path: 'search/:search-string', component: SearchResultsComponent },
  { path: 'category/:category-name', component: CategoryComponent },
  { path: 'enquiries', component: EnquiriesComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'contact-us.html', redirectTo: '/contact-us', pathMatch: 'full' },
  { path: 'page-not-found', component: PageNotFoundComponent },
  {
    path: '**',
    canActivate: [NotFoundDynamicRedirectGuardGuard],
    children: []
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
