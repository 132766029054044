<div class="enquiries" *ngIf="'/assets/images/Enquiries_HERO.jpg'"
  [style.background-image]="'url(/assets/images/Enquiries_HERO.jpg)'">
  <div class="enquiriesTitle">
    <h2>ENQUIRIES</h2>
    <p>Send us your queries and we will get back to you as soon as possible.</p>
  </div>
</div>
<section class="blogBodyWrapper">
  <div class="blogContent">
    <div *ngIf="isLoading">
      <h3>Loading...</h3>
    </div>
    <iframe (load)="onLoaded()" width="100%" height="100%" frameBorder="0" class="iframe-container" [src]="urlSafe"
      scrolling="yes"></iframe>
  </div>
</section>