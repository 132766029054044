import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { IPrmcArticle, IPrmcHomePage, IPrmcPost } from 'src/app/interfaces/contentful';
import { ContentfulService } from 'src/app/services/contentful.service';
import { MetaTagService } from 'src/app/services/meta-tag.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public homePageContent?: IPrmcHomePage;
  public newsFeedContent?: IPrmcPost[];
  public partnerFeedContent?: IPrmcArticle[];
  public isLoading: boolean = true;
  public isNZSite: boolean = false;
  constructor(private cms: ContentfulService,
    private titleService: TitleService,
    private metaTagService: MetaTagService) {
    this.titleService.setTitle("Moving products for our customers");
    this.getContent();
    this.getNewsFeed();
    this.getPartnerFeed();
  }

  async getContent() {
    this.cms.getContent('prmcHomePage', { include: 10 }).pipe(take(1)).subscribe(res => {
      this.homePageContent = res[0] as IPrmcHomePage;
      this.isLoading = false;
      //Adding meta tag
      if (this.homePageContent?.fields?.metaTag) {
        this.metaTagService.updateMetaTag(this.homePageContent?.fields?.metaTag);
      }
    });
  }

  async getNewsFeed() {
    this.cms.getContent('prmcPost', { include: 10, limit: 3, order: '-sys.createdAt' }).pipe(take(1)).subscribe(res => {
      this.isLoading = false;
      this.newsFeedContent = res as IPrmcPost[];
    });
  }

  async getPartnerFeed() {
    this.cms.getContent('prmcArticle', { include: 10, limit: 2, order: '-sys.createdAt' }).pipe(take(1)).subscribe(res => {
      this.isLoading = false;
      this.partnerFeedContent = res as IPrmcArticle[];
    });
  }

  ngOnInit(): void {
    this.isNZSite = this.titleService.isNewZealandSite();
  }

}
