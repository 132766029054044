import { Component, Input, OnInit } from '@angular/core';
import { IPrmcVideoCarouselSlide } from 'src/app/interfaces/contentful';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  @Input() slides?: IPrmcVideoCarouselSlide[] = [];

  public activeIndexInCarousel = 0;


  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Changes the active index of the carousel
   * @param indexOfSlide index of slide to change to
   */
  public changeSlideByIndex(indexOfSlide: number){
    this.activeIndexInCarousel = indexOfSlide;
  }

  /**
   * Changes slides in either the forward or backward direction
   * @param direction direction to move slides
   */
  public seekSlide(direction: "next" | "previous"){

    if(!this.slides){
      return;
    }

    if(direction == 'next'){
      if(this.activeIndexInCarousel == this.slides.length - 1){
        this.activeIndexInCarousel = 0;
      }else{
        this.activeIndexInCarousel++;
      }
    }else if(direction == "previous"){
      if(this.activeIndexInCarousel == 0){
        this.activeIndexInCarousel = this.slides.length - 1;
      }else{
        this.activeIndexInCarousel--;
      }
    }
  }


}
