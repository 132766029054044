import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { IPrmcFooter } from 'src/app/interfaces/contentful';
import { ContentfulService } from 'src/app/services/contentful.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public footerContent?: IPrmcFooter;
  public isNZSite: boolean = false;
  constructor(private cms: ContentfulService,
    private titleService: TitleService,
  ) {
    this.getContent();
  }

  ngOnInit(): void {
    this.isNZSite = this.titleService.isNewZealandSite();
  }

    /**
   * Get footer content from the CMS
   */
     getContent(){
      this.cms.getContent('prmcFooter', {include: 10}).pipe(take(1)).subscribe(res => {
        if(!res){
          console.error("Footer not found");
        }else{
          this.footerContent = res[0] as IPrmcFooter;
        }
      });
    }

}
