<section class="homePageNZ">
  <div class="heroImage" *ngIf="homePageContentNZ?.fields?.heroImage?.fields?.file?.url" [style.background-image]="
      'url(' + homePageContentNZ?.fields?.heroImage?.fields?.file?.url + ')'
    ">
    <div class="heroTitle" *ngIf="
        (homePageContentNZ?.fields?.heading || homePageContentNZ?.fields?.subheading)  
      ">
      <h2>{{ homePageContentNZ?.fields?.heading }}</h2>
      <p>{{ homePageContentNZ?.fields?.subheading }}</p>
    </div>
  </div>


  <section class="homePageBodyWrapperNZ homePageContentNZ">
    <!-- contact info -->
    <div id="contactInfo" *ngIf="(homePageContentNZ?.fields?.contactDetails)">
      <div class="contactInfoCard" *ngFor="let contactDetail of  homePageContentNZ?.fields?.contactDetails">
        <a [href]="contactDetail.fields.url">
          <fa-icon *ngIf="contactDetail.fields.icon && contactDetail.fields.icon.fields" [icon]="[
          contactDetail.fields.icon.fields.iconFamily,
          contactDetail.fields.icon.fields.iconName  
        ]"></fa-icon>
          <span>
            {{ contactDetail.fields.displayText }}
          </span>
        </a>
      </div>
    </div>
    <div [appRenderRichText]="homePageContentNZ?.fields?.bodyContent"></div>

    <div class="cardContainer">
      <ng-container *ngFor="let content of homePageContentNZ?.fields?.additionalBodyContent">
        <container-element [ngSwitch]="content.sys.contentType.sys.id">
          <!-- rich text -->
          <div [appRenderRichText]="content.fields['content']" *ngSwitchCase="'prmcRichText'">
            ...
          </div>
          <!-- prmc Image Text Content -->
          <div class="cardContent" *ngSwitchCase="'prmcImageTextContent'">
            <div [appRenderRichText]="content.fields['imageTextContent']">
              ...
            </div>
          </div>
          <!-- other components can be displayed with switch cases below -->
          <!--default case when there are no matches, let them know we don't know how to render this content i.e. it's unsupported -->
          <div *ngSwitchDefault>
            Unsupported content type: {{ content.sys.contentType.sys.id }}
          </div>
        </container-element>
      </ng-container>
    </div>
    <div id="nz-disclaimer" [appRenderRichText]="homePageContentNZ?.fields?.moreBodyContent"></div>
  </section>
  <div *ngIf="homePageContentNZ?.fields?.pageEndImage?.fields?.file?.url" class="pageEndImage" [style.backgroundImage]="
      'url(' + homePageContentNZ?.fields?.pageEndImage?.fields?.file?.url + ')'
    "></div>
</section>