<div class="heroImage" *ngIf="heroImageURL" [style.backgroundImage]="'url(' + heroImageURL + ')'">
</div>
<div class="result">
  <h2>Results</h2>

<section *ngIf="posts.length > 0 ; else loding">
  <div class="newsTile container" *ngFor="let post of posts | paginate: { itemsPerPage: 10, currentPage: pageNumber }">

    <div class="newsTileImage containerItem" [style.backgroundImage]="'url(' + post.fields.cardImage?.fields?.file?.url + ')'">
    </div>

    <div class="newsTileContent containerItem">
      <span class="publishDate"> {{post.fields.publishDate| date: 'dd MMM yyyy' }}</span>
      <h3>{{post.fields.heading}}</h3>
      <p>{{post.fields.subheading}}</p>

      <a [routerLink]="'/pages/' +category +'/'+ post.fields.slug">Read More</a>
    </div>
  </div>
  <pagination-controls (pageChange)="pageNumber = $event" class="paginationControls"></pagination-controls>
</section>
</div>
<ng-template #loding>Loding...</ng-template>
