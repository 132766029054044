import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { IPrmcMainNavigation } from 'src/app/interfaces/contentful';
import { ContentfulService } from 'src/app/services/contentful.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  public navItems?: IPrmcMainNavigation;
  public searchString: string = "";
  public isSlideOutNavOpen: boolean = false;

  constructor(private cms: ContentfulService, private router: Router) {
    this.getContent();
  }

  /**
   * Get nav items from the CMS
   */
  getContent(){
    this.cms.getContent('prmcMainNavigation', {include: 10}).pipe(take(1)).subscribe(res => {
      if(!res){
        console.error("Nav not found");
      }else{
        this.navItems = res[0] as IPrmcMainNavigation;
      }
    });

  }

  public search(){
    if(this.searchString){
      this.router.navigateByUrl("/search/" + encodeURIComponent(this.searchString));
    }
  }

  ngOnInit(): void {
  }


}
