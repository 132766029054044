import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { catchError, map, of, take } from 'rxjs';
import { IPrmcNewZealandHomePage } from 'src/app/interfaces/contentful';
import { ContentfulService } from 'src/app/services/contentful.service';
import { MetaTagService } from 'src/app/services/meta-tag.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-nz-home',
  templateUrl: './nz-home.component.html',
  styleUrls: ['./nz-home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NZHomeComponent implements OnInit {
  homePageContentNZ?: IPrmcNewZealandHomePage;
  public isLoading: boolean = true;
  constructor(private cms: ContentfulService,
    private titleService: TitleService,
    private metaTagService: MetaTagService) {
    this.titleService.setTitle("Moving products for our customers");
    this.getContent();
  }

  ngOnInit(): void {
    this.getContent(); 
  }

  async getContent() {
    this.cms.getContent('prmcNewZealandHomePage', { include: 10 }).pipe(take(1)).subscribe(res => {
      this.homePageContentNZ = res[0] as IPrmcNewZealandHomePage;
      this.isLoading = false;
      //Adding meta tag
      if (this.homePageContentNZ?.fields?.metaTag) {
        this.metaTagService.updateMetaTag(this.homePageContentNZ?.fields?.metaTag);
      }
    });
  }

}
