<div *ngIf="alert" id="site-wide-alert-container"
[class.warning]="alert.fields.type === 'warning'"
[class.error]="alert.fields.type === 'error'"
[class.success]="alert.fields.type === 'success'"
[class.informational]="alert.fields.type === 'informational'"
>
    <h2 *ngIf="alert.fields.heading">
       {{alert.fields.heading}} 
    </h2>
    <div id="site-wide-alert" *ngIf="alert.fields.content" [appRenderRichText]="alert.fields.content">
    </div>
</div>