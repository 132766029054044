import { InjectionToken, NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './components/nav/nav.component';
import { VideoCarouselComponent } from './video-carousel/video-carousel.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faAngleLeft, faAngleRight, faBars, faChevronRight, faCircle, faComment, faEnvelope, faGlobeAsia, faLock, faPhone, faSearch, faTimes, faTruck } from '@fortawesome/free-solid-svg-icons';
import { HomeComponent } from './pages/home/home.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { FooterComponent } from './components/footer/footer.component';
import { BlogPostComponent } from './pages/blog-post/blog-post.component';
import { RenderRichTextDirective } from './directives/render-rich-text.directive';
import { FormsModule } from '@angular/forms';
import { SearchResultsComponent } from './pages/search-results/search-results.component';
import { HandleCMSLinkDirective } from './directives/handle-cms-link.directive';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { CategoryComponent } from './pages/category/category.component';
import { NavigationPillsComponent } from './components/navigation-pills/navigation-pills.component'
import { HttpClientModule } from '@angular/common/http';
import { EnquiriesComponent } from './pages/enquiries/enquiries.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { AddressContainerComponent } from './components/address-container/address-container.component';
import { AuthorControlsComponent } from './author-controls/author-controls.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AlertComponent } from './components/alert/alert.component';
import { NZHomeComponent } from './pages/nz-home/nz-home.component';
export const WINDOW = new InjectionToken('WINDOW');

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    VideoCarouselComponent,
    HomeComponent,
    CarouselComponent,
    FooterComponent,
    BlogPostComponent,
    RenderRichTextDirective,
    SearchResultsComponent,
    HandleCMSLinkDirective,
    CategoryComponent,
    NavigationPillsComponent,
    EnquiriesComponent,
    ContactUsComponent,
    AddressContainerComponent,
    AuthorControlsComponent,
    PageNotFoundComponent,
    AlertComponent,
    NZHomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule,
  ],
  providers: [
    {
      provide: WINDOW,
      useValue: window,
    },
    Meta
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faChevronRight, faBars, faTimes, faSearch, faTruck, faCircle, faAngleRight, faAngleLeft, faGlobeAsia, faEnvelope, faLock, faComment, faPhone, faLinkedin);
  }
}
