<div class="navigationPills">
    <!-- Navigation Pills -->
    <!--Queries List Section-->
    <div class="queries" id="queries-list">
        <div class="shrinkSidenav">
            <button class="btn btnSuccess query move-left"> <span><img src="assets/icons/Login.png"
                        class="queryIcons"></span> <a href="https://tms.woolworths.com.au/tm/framework/Frame.jsp"
                    target="_blank">TMS User Login</a> </button>
            <button class="btn btnSuccess query move-left"> <span><img src="assets/icons/Enquiries.png"
                        class="queryIcons"></span> <a routerLink="enquiries" target="_self">Quotes Enquiries</a>
            </button>
        </div>

    </div>
</div>
